import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getSpecifications = async (category = null) => {
  try {
    return await getData(`/without-login/about-us/specifications`);
  } catch (error) {
    console.log(error);
  }
};

export const getOurMissions = async (category = null) => {
  try {
    return await getData(`/without-login/about-us/our-mission`);
  } catch (error) {
    console.log(error);
  }
};

export const getDignitaries = async (category = null) => {
  try {
    return await getData(`/without-login/about-us/dignitaries`);
  } catch (error) {
    console.log(error);
  }
};
