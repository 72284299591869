import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const addWishlist = async (d) => {
  try {
    return await postData(`/wishlist/add`, {
      product_id: d?.product_id,
      variant_id: d?.variant_id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getWishlist = async () => {
  try {
    return await getData(`/wishlist/show`);
  } catch (error) {
    console.log(error);
  }
};

export const wishlistContext = async (signin, setWishlist) => {
  if (signin) {
    const res = await getWishlist();
    if (res?.success) {
      setWishlist(res?.data);
    }
  } else {
    setWishlist([]);
  }
};
