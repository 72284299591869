import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Common_Button from "../../../CommonComponents/Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import "./Get_A_Quote.css";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { postQuote } from "../../../../utils/apis/common/Common";
function Get_A_Quote(props) {
  const [modalShow, setModalShow] = useState(false);

  const handleSendMessage = () => {
    setModalShow(true);
    props.onHide();

    // Automatically close success modal after 3 seconds
    setTimeout(() => {
      setModalShow(false);
    }, 1000);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
   
    const res = await postQuote(data);
    if (res?.success) {
      reset();
      handleSendMessage();
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    setValue("product_name", props.product_name);
    setValue("product_id", props.product_id);
  }, [props]);

 
  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Get A Quote
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0">
                  <div className="col-md-12">
                    <div className="log_in_form">
                      <Form>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Enter Company Name*"
                            {...register("company_name", {
                              required: "company_name is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.company_name,
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Enter Contact Person Name*"
                            {...register("name", {
                              required: "name is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.name,
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Enter Email Id"
                            {...register("email", {
                              required: "Email Id required",
                              pattern: {
                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/,
                                message: "Email Id Invalid",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email,
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Enter Product Name or Code*"
                            {...register("product_name", {
                              required: "product_name is required",
                            })}
                            disabled
                            className={classNames("", {
                              "is-invalid": errors?.product_name,
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="text"
                            placeholder="Quantity"
                            {...register("quantity", {
                              required: "quantity is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.quantity,
                            })}
                          />
                        </Form.Group>
                      </Form>

                      <div className="btn-holder">
                        <Common_Button
                          className="SubmitBtn green text"
                          text="Submit"
                          //   onClick={handleSubmit}
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text="Quote Sent Successfully!"
      />
    </>
  );
}

export default Get_A_Quote;
