import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import Edit_Address_Modal from "../CommonComponents/Common_Modal/Edit_Address_Modal/Edit_Address_Modal";
import {
  AddAddress,
  DefaultAddress,
  DeleteAddress,
  GetAllAddress,
} from "../../utils/apis/address";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
const Address = (props) => {
  const options = [
    { value: "Home", label: "Home" },
    { value: "Work", label: "Work" },
    { value: "Office", label: "Office" },
  ];
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);

  // --------------------------------------------------------------------------------------------------------------------
  const [allAddress, SetAllAddress] = useState([]);
  const getData = async () => {
    const res = await GetAllAddress();
    SetAllAddress(res.data);
  };

  const ChangeDefaultAddress = async (id) => {
    const res = await DefaultAddress(id);
    getData();
  };

  const DeletedAddress = async (id) => {
    const res = await DeleteAddress(id);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  // Add Address
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await AddAddress(data);
      reset();
      getData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <section className="Address">
        <Row>
          <Col xxl={4} xl={4} lg={12} className="right_border">
            {allAddress?.map((val, index) => (
              <div className="Main-address" key={index}>
                <div className="Edit">
                  <div className="image-map">
                    <img
                      src={process.env.PUBLIC_URL + "/assests/Account/a1.png"}
                      className="map"
                      alt="Mansa Nutri Logo"
                    />
                    <div className="circle">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>
                  </div>
                  <div className="content">
                    <p className="sub-text">{val?.add_type}</p>
                    <p className="text">{val?.contact_no}</p>
                    <p className="text">
                      {val?.address} , {val?.landmark}- {val?.zip_code}
                    </p>
                  </div>
                  <div className="button">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        checked={val?.status}
                        onClick={() => ChangeDefaultAddress(val?.id)}
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                    </div>
                    <div className="delet-address-holder">
                      <div className="CartPage">
                        <div className="Main-Table">
                          <div
                            className="delete-btn table-bordered"
                            onClick={() => DeletedAddress(val?.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} className="icon" />
                          </div>
                        </div>
                      </div>
                      <p
                        className="text edit"
                        onClick={() => setSuccessfullModalShow(val?.id)}
                      >
                        Edit
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="Main-address">
              <div className="Edit">
                <div className="image-map">
                  <img
                    src={process.env.PUBLIC_URL + "/assests/Account/a1.png"}
                    className="map"
                    alt="Mansa Nutri Logo"
                  />
                  <div className="circle">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                </div>
                <div className="content">
                  <p className="sub-text">Home</p>
                  <p className="text">John Doe</p>
                  <p className="text">
                    E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra -
                    400703
                  </p>
                </div>
                <div className="button">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                  </div>
                  <div className="delet-address-holder">
                    <div className="CartPage">
                      <div className="Main-Table">
                        <div className="delete-btn table-bordered">
                          <FontAwesomeIcon icon={faTrash} className="icon" />
                        </div>
                      </div>
                    </div>
                    <p
                      className="text edit"
                      onClick={() => setSuccessfullModalShow(true)}
                    >
                      Edit
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Main-address">
              <div className="Edit">
                <div className="image-map">
                  <img
                    src={process.env.PUBLIC_URL + "/assests/Account/a1.png"}
                    className="map"
                    alt="Mansa Nutri Logo"
                  />
                  <div className="circle">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                </div>
                <div className="content">
                  <p className="sub-text">Home</p>
                  <p className="text">John Doe</p>
                  <p className="text">
                    E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra -
                    400703
                  </p>
                </div>
                <div className="button">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                  </div>
                  <div className="delet-address-holder">
                    <div className="CartPage">
                      <div className="Main-Table">
                        <div className="delete-btn table-bordered">
                          <FontAwesomeIcon icon={faTrash} className="icon" />
                        </div>
                      </div>
                    </div>

                    <p
                      className="text edit"
                      onClick={() => setSuccessfullModalShow(true)}
                    >
                      Edit
                    </p>
                  </div>
                  <Edit_Address_Modal
                    show={modalSuccessfullShow}
                    onHide={() => setSuccessfullModalShow(false)}
                  />
                </div>
              </div>
            </div> */}
          </Col>
          <Col xxl={8} xl={8} lg={12}>
            <div className="Address-Content">
              <div className="main-heading-text">
                <div className="mb-3">
                  {/* <Form.Check
                    type="radio"
                    name="name"
                    label="Add new address"
                  /> */}
                  <b>
                    Add new address
                    {!allAddress?.some((item) => item?.status == 1) && (
                      <span className="text-danger">
                        {" "}
                        ( Please Selete Address or Add Address ){" "}
                      </span>
                    )}
                  </b>
                </div>
              </div>
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formAddressName"
                >
                  <Form.Label
                    column
                    xxl="3"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                  >
                    Address Type *
                  </Form.Label>
                  <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                    <Controller
                      name="add_type"
                      {...register("add_type", {
                        required: "Select Address Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.add_type ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={options}
                        />
                      )}
                    />
                    {/* <Select
                      classNamePrefix="selectOptions"
                      options={options}
                      placeholder="--- please select ---"
                    /> */}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formFlatNo">
                  <Form.Label
                    column
                    xxl="3"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                  >
                    Flat no./House/bldg*
                  </Form.Label>
                  <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Flat no./House/bldg"
                      className={classNames("", {
                        "is-invalid": errors?.address,
                      })}
                      {...register("address", {
                        required: "Flat no./House/bldg is required",
                      })}
                    />{" "}
                    {/* <Form.Control
                      type="text"
                      placeholder="Flat no./House/bldg"
                    /> */}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formColony">
                  <Form.Label
                    column
                    xxl="3"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                  >
                    Colony/street/landmark
                  </Form.Label>
                  <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                    <Form.Control
                      type="text"
                      name="landmark"
                      placeholder="Colony/street/landmark"
                      className={classNames("", {
                        "is-invalid": errors?.landmark,
                      })}
                      {...register("landmark", {
                        required: "Colony/street/landmark is required",
                      })}
                    />
                    {/* <Form.Control
                      type="text"
                      placeholder="Colony/street/landmark"
                    /> */}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formZipCode">
                  <Form.Label
                    column
                    xxl="3"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                  >
                    Zip code*
                  </Form.Label>
                  <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                    <Form.Control
                      type="text"
                      name="zip_code"
                      placeholder="Zip code"
                      className={classNames("", {
                        "is-invalid": errors?.zip_code,
                      })}
                      {...register("zip_code", {
                        required: "Zip code is required",
                        pattern: {
                          value: /^\d{6}$/,
                          message: "Please enter a valid 6-digit",
                        },
                      })}
                    />
                    {/* <Form.Control type="text" placeholder="Zip code" /> */}
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formMobileNumber"
                >
                  <Form.Label
                    column
                    xxl="3"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="12"
                    xs="12"
                  >
                    Mobile number*
                  </Form.Label>
                  <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                    <Form.Control
                      type="number"
                      name="contact_no"
                      placeholder="Mobile number"
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      {...register("contact_no", {
                        required: "Mobile number is required",
                        pattern: {
                          value: /^\d{10}$/,
                          message:
                            "Please enter a valid 10-digit mobile number",
                        },
                      })}
                    />
                    {/* <Form.Control type="text" placeholder="Mobile number" /> */}
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
        <div className="  apply_now_btn">
          {/* <Button_Common onClick={props.prevStep} className="back-btn me-3 text"  text="PREVIOUS">
                        Back
                    </Button_Common> */}

          <Button_Common
            type="button"
            // onClick={props.nextStep}
            onClick={handleSubmit(onSubmit)}
            className="tabs-btn text green me-2"
            text="SAVE"
          ></Button_Common>
          {allAddress?.length && allAddress?.some((item) => item?.status == 1) && (
            <Button_Common
              type="button"
              onClick={props.nextStep}
              // onClick={handleSubmit(onSubmit)}
              className="tabs-btn text green"
              text="NEXT"
            ></Button_Common>
          )}
        </div>
      </section>

      <Edit_Address_Modal
        show={modalSuccessfullShow}
        onHide={() => setSuccessfullModalShow(false)}
        options={options}
        getData={getData}
      />
    </>
  );
};

export default Address;
