import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import Common_Button from "../../../CommonComponents/Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
import { EditAddress, GetAddress } from "../../../../utils/apis/address";
function Edit_Address_Modal(props) {
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  // const handleSubmit = () => {
  //   setModalShow(true);
  //   props.onHide();
  // };

  useEffect(() => {
    let timer;
    if (modalShow) {
      timer = setTimeout(() => {
        setModalShow(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [modalShow]);

  // Add Address
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // console.log(data);
      const res = await EditAddress(props.show, data);
      reset();
      props.getData();
      setModalShow(true);
      props.onHide();
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async (id) => {
    const res = await GetAddress(id);
    reset(res.data);
  };

  useEffect(() => {
    GetEditData(props.show);
  }, [props.show]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0  ">
                  <div className="col-md-12">
                    <div className="log_in_form">
                      <Form>
                        <Form.Group className="mb-2" controlId="">
                          <Controller
                            name="add_type"
                            {...register("add_type", {
                              required: "Select Address Type",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.add_type
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={props.options}
                              />
                            )}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="">
                          <Form.Control
                            type="text"
                            name="address"
                            placeholder="Flat no./House/bldg"
                            className={classNames("", {
                              "is-invalid": errors?.address,
                            })}
                            {...register("address", {
                              required: "Flat no./House/bldg is required",
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="">
                          <Form.Control
                            type="text"
                            name="landmark"
                            placeholder="Colony/street/landmark"
                            className={classNames("", {
                              "is-invalid": errors?.landmark,
                            })}
                            {...register("landmark", {
                              required: "Colony/street/landmark is required",
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="">
                          <Form.Control
                            type="text"
                            name="zip_code"
                            placeholder="Zip code"
                            className={classNames("", {
                              "is-invalid": errors?.zip_code,
                            })}
                            {...register("zip_code", {
                              required: "Zip code is required",
                              pattern: {
                                value: /^\d{6}$/,
                                message: "Please enter a valid 6-digit",
                              },
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="">
                          <Form.Control
                            type="number"
                            name="contact_no"
                            placeholder="Mobile number"
                            className={classNames("", {
                              "is-invalid": errors?.contact_no,
                            })}
                            {...register("contact_no", {
                              required: "Mobile number is required",
                              pattern: {
                                value: /^\d{10}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number",
                              },
                            })}
                          />
                        </Form.Group>
                      </Form>

                      <div className="btn-holder">
                        <Common_Button
                          className="SubmitBtn green text"
                          text="Submit"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Render the Bottom_Successfull_Modal with modalShow state */}
      <Bottom_Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        text="Address Changed Successfully!"
      />
    </>
  );
}

export default Edit_Address_Modal;
