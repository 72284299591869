import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const NewMobileOTPSend = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/new-mobile-otp-send`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const MobileOTPSend = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/mobile-otp-send`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const MobileOTPVerify = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/mobile-verify-otp`,
      data
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
