import React, { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { CancelOrderReason } from "../../../../utils/apis/master/Master";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";

function Cancel_Reason_Modal(props) {
  const { IMG_URL, htmlToReactParser } = useContext(Context);
  const [data, setData] = useState([]);

  const getData = async (page = 1) => {
    const res = await CancelOrderReason();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);
  const { isOpen, onClose } = props;

  //

  const handleReasonChange = (event) => {
    props.setValue("order_cancel_reason_id", event.target.value);
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="md"
      className="Modal-holder"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select a reason for Cancel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="select_reason">
          <Form>
            {data?.map((value, index) => (
              <Form.Check
                key={index}
                label={value.name}
                value={value?.id}
                name="order_cancel_reason_id"
                type="radio"
                className="mini-text"
                {...props.register("order_cancel_reason_id", {
                  required: "Order cancel reason is required",
                })}
                onChange={handleReasonChange}
              />
            ))}

            {/* <Form.Check
              label="Wrong contact number entered"
              name="reason"
              type="radio"
              className="mini-text"
            />

            <Form.Check
              label="Incorrect product size/colour/type ordered"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Purchased product from somewhere else"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Wrong address selected"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Product not required anymore"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Incorrect payment method selected"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Expected delivery time is too long"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Other"
              name="reason"
              type="radio"
              className="mini-text"
            /> */}
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Cancel_Reason_Modal;
