import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button_Common from "../../Button_Common/Button_Common";
import Login_Modal from "../Login_Modal/Login_Modal";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";
import { ForgetPasswordEmailOtpSend } from "../../../../utils/apis/otp/otp";
function Forget_Password_Modal({ onClick, ...props }) {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleOtpSubmit = async () => {
    if (RegxExpression.email.test(props.getValues("email"))) {
      //   console.log(props.getValues("email"));
      const res = await ForgetPasswordEmailOtpSend(props.getValues("email"));
      if (res.success) {
        props.clearErrors();
        props.onHide();
        props.setOtpModal();
      } else {
        props.setError("email", {
          message: "Email Not Exits",
        });
      }
    } else {
      props.setError("email", {
        message: "Enter Valid Email",
      });
    }
    // OTP Modal Open
    // props.onHide();
    // props.setOtpModal();
    // setTimeout(() => {
    //   props.setOtpModalFalse();
    //   props.setNewPasswordModal();
    // }, 3000);
  };

  const handleBackToHome = () => {
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reset Your Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0">
                  <div className="col-md-12">
                    <div className="heading-holder">
                      <p>
                        You will receive an email from us requesting you to .
                      </p>
                    </div>

                    <div className="log_in_form">
                      <Form>
                        <Form.Group className="mb-2" controlId="">
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            {...props.register("email", {
                              required: "Email is required",
                              pattern: {
                                value: RegxExpression.email,
                                message: "Invalid email address",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": props.errors?.email,
                              "is-valid": props.getValues("email"),
                            })}
                          />
                        </Form.Group>
                      </Form>

                      <div className="btn-holder">
                        <Button_Common
                          className="SubmitBtn-main text me-3"
                          text="Cancel"
                          onClick={() => {
                            props.onHide();
                            setShowLoginModal(false);
                          }}
                        />
                        <Button_Common
                          className="SubmitBtn-main green text"
                          text="Submit"
                          onClick={handleOtpSubmit}
                        />
                      </div>

                      <div className="back_to_span">
                        <Link to={"/"} onClick={handleBackToHome}>
                          <p>Back to Home {">"}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Login_Modal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
    </>
  );
}

export default Forget_Password_Modal;
