import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

import { Context, variant } from "../../context";
import { useContext } from "react";

import { getProductDetail, getVariant } from "../Product/Product";

export const loginCart = async () => {
  let cartItems = [];
  const existingCart = Cookies.get("mansa_nutri_cart");
  if (existingCart) {
    cartItems = JSON.parse(existingCart);
  }
  if (cartItems.length > 0) {
    await Promise.all(
      cartItems.map(async (value) => {
        const data = await postData(`/cart/add`, {
          product_id: value?.product_id,
          variant_id: value?.variant_id,
          quantity: value?.quantity,
        });
      })
    );
  }
  await Cookies.remove("mansa_nutri_cart");
};

export const AddToCart = async (d) => {
  try {
    if (Cookies.get("mansa_nutri_security")) {
      const res = await postData(`/cart/add`, {
        product_id: d.product_id,
        variant_id: d.variant_id,
        quantity: d.quantity,
      });
      return true;
    } else {
      let cartItems = [];

      const existingCart = Cookies.get("mansa_nutri_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }
      // const res = await getVariant(d?.variant_id);
      // const res1 = await getProductDetail(d?.product_id);
      // if (res?.success) {
      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.product_id === d?.product_id && item.variant_id === d?.variant_id
      );

      if (existingItemIndex === -1) {
        const newItem = { ...d };
        newItem.id = uuidv4();
        cartItems.push(newItem);
      } else {
        cartItems[existingItemIndex].quantity++;
      }

      Cookies.set("mansa_nutri_cart", JSON.stringify(cartItems));
      // }
    }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const CartQuantity = async (id, type, signin, setCart) => {
  try {
    if (Cookies.get("mansa_nutri_security")) {
      if (id) {
        await postData(`/cart/quantity`, {
          cart_id: id,
          type: type,
        });
        updateCartCount(signin, setCart);
      }

      // return true;
    } else {
      // console.log(id);
      // console.log(type);
      let cartItems = [];

      const existingCart = Cookies.get("mansa_nutri_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      // console.log(id);

      const existingItemIndex = cartItems.findIndex((item) => item.id === id);
      if (existingItemIndex === -1) {
      } else {
        if (cartItems[existingItemIndex]?.quantity === 1 && type === "minus") {
          cartItems.splice(existingItemIndex, 1);
        } else {
          if (type === "minus") {
            cartItems[existingItemIndex].quantity -= 1;
          } else if (type === "add") {
            cartItems[existingItemIndex].quantity += 1;
          } else if (type === "delete") {
            cartItems.splice(existingItemIndex, 1);
          }
        }
        Cookies.set("mansa_nutri_cart", JSON.stringify(cartItems));
      }
      updateCartCount(signin, setCart);
    }
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getCart = async () => {
  try {
    return await getData(`/cart/show`);
  } catch (error) {
    console.log(error);
  }
};

export const getCartCheck = async (data) => {
  try {
    if (Cookies.get("mansa_nutri_security")) {
      const queryParams = new URLSearchParams(data).toString();
      return await getData(`/cart/getCart?${queryParams}`);
    } else {
      let cartItems = [];
      const existingCart = Cookies.get("mansa_nutri_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }
      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.product_id == data?.product_id &&
          item.variant_id == data?.variant_id
      );
      if (existingItemIndex === -1) {
        return { success: false, cartItem: cartItems[existingItemIndex] };
      } else {
        return { success: true, cartItem: cartItems[existingItemIndex] };
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCartCount = async (
  signin,
  setCart
  // setCartCount = 0
) => {
  if (signin) {
    try {
      const cartItems = await getData(`/cart/show`);
      setCart(cartItems?.data || []);
      // setCartCount(cartItems?.data?.length || 0);
    } catch (error) {
      console.log(error);
    }
  } else if (Cookies.get("mansa_nutri_cart")) {
    // setCartCount(JSON.parse(Cookies.get("mansa_nutri_cart")).length);
    setCart(JSON.parse(Cookies.get("mansa_nutri_cart") || []));
  }
};

export const CartStatus = async (id, data, signin, setCart) => {
  try {
    if (signin) {
      const res = await postData(`/cart/status/${id}`, data);
    } else {
      let cartItems = [];

      const existingCart = Cookies.get("mansa_nutri_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex((item) => item.id === id);
      if (existingItemIndex === -1) {
      } else {
        cartItems[existingItemIndex].status = data.status;
        Cookies.set("mansa_nutri_cart", JSON.stringify(cartItems));
      }
    }

    updateCartCount(signin, setCart);
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getSeletedCart = async () => {
  try {
    return await getData(`/cart/seleted`);
  } catch (error) {
    console.log(error);
  }
};
