import React, { useContext, useEffect, useState } from "react";
import "./CartPage.css";
import Banner_Heading_Text from "../../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import { Col, Container, Form, Row } from "react-bootstrap";
import IncreamentDecreament from "../../CommonComponents/IncreamentDecreament/IncreamentDecreament";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Bottom_Successfull_Modal from "../../CommonComponents/Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { Context } from "../../../utils/context";
import {
  CartQuantity,
  CartStatus,
  getCart,
} from "../../../utils/apis/addToCart/addToCart";
import { useForm } from "react-hook-form";

const CartPage = (props) => {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "CART", link: "/cart", className: "active" },
  ]);

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleShowModal = async (id, type = "delete") => {
    const res = await CartQuantity(id, type);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    let timer;
    if (showModal) {
      timer = setTimeout(() => {
        setShowModal(false);
        // navigate("/product-detail");
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [showModal, navigate]);

  // ------------------------------------------------------------------------------------------------
  const {
    IMG_URL,
    signin,
    triggerCartUpdate,
    Cookies,
    cart,
    setCart,
  } = useContext(Context);

  //   const [cart, setCart] = useState([]);
  //   const getCartQuantity = async (id, type) => {
  //     setDiscount(0);
  //     setCoupon();
  //     setCouponStatus(false);
  //     setError("code", "");

  //     await CartQuantity(id, type);
  //     if (Cookies.get("mansa_nutri_cart")) {
  //       if (JSON.parse(Cookies.get("mansa_nutri_cart")).length === 0) {
  //         navigate("/cart");
  //       }
  //       setCart(JSON.parse(Cookies.get("mansa_nutri_cart")));
  //       withoutCart();
  //     }
  //     if (signin) {
  //       await cartGet();
  //     }
  //     triggerCartUpdate();
  //   };

  // console.log("cart", cart);

  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
     
      if (res?.data.length === 0) {
        navigate("/cart");
      }
      setCart(res?.data);
    }
  };

  const withoutCart = async () => {
    if (Cookies.get("mansa_nutri_cart")) {
      if (JSON.parse(Cookies.get("mansa_nutri_cart")).length === 0) {
        navigate("/cart");
      }
      await setCart(JSON.parse(Cookies.get("mansa_nutri_cart")));
    }
  };

  useEffect(() => {
    if (signin) {
      cartGet();
    } else {
      withoutCart();
    }
  }, [Cookies.get("mansa_nutri_cart"), signin, showModal]);

  useEffect(() => {
    TotalCalculations();
  }, [cart]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [selete, setSelete] = useState([]);

  const TotalCalculations = async () => {
  
    let total_amount = 0;
    let discount = 0;
    let total_tax = 0;
    let shipping = 0;
    let total = 0;

    const selete = [];
    for (const iterator of cart) {
      if (iterator.status == 1) {
        selete.push(iterator.id);
        // total_amount
        const amount = Number(iterator.price) * Number(iterator.quantity);
        total_amount = total_amount + amount;
        // total tax
        const tax = (amount / 100) * Number(iterator.tax_percentage);
        total_tax = total_tax + tax;
      }
    }
    setSelete(selete);
    total = total_amount + total_tax;

    setValue("total_amount", total_amount);
    setValue("discount", discount);
    setValue("total_tax", total_tax);
    setValue("shipping", shipping);
    setValue("total", +total);
    reset(getValues());
  };

  const SelectAll = async (e) => {
    for (const iterator of cart) {
      CartStatus(
        iterator?.id,
        {
          status: e.target.checked,
        },
        signin,
        setCart
      );
    }
  };
  return (
    <>
      <section className="CartPage">
        <Container>
          <Banner_Heading_Text
            headingText={""}
            breadcrumbItems={breadcrumbItems}
          />
          <div className="main-cart">
            <Form.Check
              type="checkbox"
              label="Select All"
              checked={selete?.length == cart?.length}
              onClick={SelectAll}
            />
            <Row className="justify-content-center mt-4">
              <Col xxl={9} xl={9} lg={12}>
                <div className="Main-Table">
                  <div className="table-responsive">
                    <table class="table ">
                      <thead>
                        <tr>
                          <th className="check"></th>
                          <th className="table-h1 product sub-text table-bordered">
                            Product
                          </th>
                          <th className="table-h1 product-name sub-text table-bordered">
                            Product Name
                          </th>
                          <th className="table-h1  size sub-text table-bordered">
                            Pack Size
                          </th>
                          <th className="table-h1 packs  sub-text table-bordered">
                            Qty Of Packs
                          </th>
                          <th className="table-h1 amount sub-text table-bordered">
                            Amount
                          </th>
                          <th className="table-h1  delete sub-text table-bordered">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart?.map((item, index) => (
                          <tr key={index}>
                            <td className="check">
                              <Form.Check
                                type="checkbox"
                                checked={item.status}
                                onChange={(e) => {
                                  CartStatus(
                                    item?.id,
                                    {
                                      status: e.target.checked,
                                    },
                                    signin,
                                    setCart
                                  );
                                }}
                              />
                            </td>
                            <td className="main-data table-bordered">
                              <div className="image-cart">
                                <img
                                  src={
                                    signin ? IMG_URL + item.image : item.image
                                  }
                                  className="product"
                                  alt="Mansa Nutri Logo"
                                />
                              </div>
                            </td>
                            <td className="main-data mini-text table-bordered">
                              {item?.name} ( {item?.short_description})
                            </td>
                            <td className="main-data mini-text table-bordered">
                              {item?.v_quantity}
                              {item?.unit}
                            </td>
                            <td className="main-data table-bordered">
                              <IncreamentDecreament
                                quantity={Number(item?.quantity)}
                                id={item?.id}
                              />
                            </td>
                            <td className="main-data mini-text table-bordered">
                              ₹ {item?.price}
                            </td>
                            <td className="main-data table-bordered">
                              <div
                                className="delete-btn table-bordered"
                                onClick={() => handleShowModal(item?.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="icon"
                                />
                              </div>
                              {/* <Button_Common className="delete" /> */}
                            </td>
                          </tr>
                        ))}
                        {/* <tr>
                          <td className="check">
                            {" "}
                            <Form.Check type="checkbox" />
                          </td>
                          <td className="main-data table-bordered">
                            <div className="image-cart">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assests/Carts/cart1.png"
                                }
                                className="product"
                                alt="Mansa Nutri Logo"
                              />
                            </div>
                          </td>
                          <td className="main-data mini-text table-bordered">
                            Balsam Tolu (Reconstitution)
                          </td>
                          <td className="main-data mini-text table-bordered">
                            1 G
                          </td>
                          <td className="main-data table-bordered">
                            <IncreamentDecreament />
                          </td>
                          <td className="main-data mini-text table-bordered">
                            ₹.408
                          </td>
                          <td className="main-data table-bordered">
                            <div
                              className="delete-btn table-bordered"
                              onClick={handleShowModal}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="icon"
                              />
                            </div>
                          
                          </td>
                        </tr> */}
                        {/* <tr>
                          <td className="check">
                            {" "}
                            <Form.Check type="checkbox" />
                          </td>
                          <td className="main-data table-bordered">
                            <div className="image-cart">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assests/Carts/cart2.png"
                                }
                                className="product"
                                alt="Mansa Nutri Logo"
                              />
                            </div>
                          </td>
                          <td className="main-data mini-text table-bordered">
                            Carrot Seeds
                          </td>
                          <td className="main-data mini-text table-bordered">
                            1 G
                          </td>
                          <td className="main-data table-bordered">
                            <IncreamentDecreament />
                          </td>
                          <td className="main-data mini-text table-bordered">
                            ₹.6889
                          </td>
                          <td className="main-data table-bordered">
                            <div
                              className="delete-btn table-bordered"
                              onClick={handleShowModal}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="icon"
                              />
                            </div>
                          
                          </td>
                        </tr>
                        <tr>
                          <td className="check">
                            {" "}
                            <Form.Check type="checkbox" />
                          </td>
                          <td className="main-data table-bordered">
                            <div className="image-cart">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assests/Carts/cart3.png"
                                }
                                className="product"
                                alt="Mansa Nutri Logo"
                              />
                            </div>
                          </td>
                          <td className="main-data mini-text table-bordered">
                            Cassia Flower
                          </td>
                          <td className="main-data mini-text table-bordered">
                            1 G
                          </td>
                          <td className="main-data table-bordered">
                            <IncreamentDecreament />
                          </td>
                          <td className="main-data mini-text table-bordered">
                            ₹.567
                          </td>
                          <td className="main-data table-bordered">
                            <div
                              className="delete-btn table-bordered"
                              onClick={handleShowModal}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="icon"
                              />
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={6}>
                <div className="Order-Summary">
                  <h1 className="sub-text ">Order Summary</h1>
                  <div className="Total main">
                    <p className="mini-text">Total amount</p>
                    <p className="mini-text">₹{getValues("total_amount")}</p>
                  </div>
                  <div className="Total main">
                    <p className="mini-text">Discount</p>
                    <p className="mini-text">₹{getValues("discount")}</p>
                  </div>
                  <div className="Total main">
                    <p className="mini-text">Tax</p>
                    <p className="mini-text">₹{getValues("total_tax")}</p>
                  </div>
                  <div className="Total ">
                    <p className="mini-text">Shipping</p>
                    <p className="mini-text">₹{getValues("shipping")}</p>
                  </div>
                  <div className="total-payment">
                    <div className="total-main">
                      <p className="sub-text">Total</p>
                      <p className="sub-text">₹{getValues("total")}</p>
                    </div>
                  </div>

                  {signin && cart.length ? (
                    <Link to="/address">
                      <div className="Proceed">
                        <Button_Common
                          text="Proceed to Checkout "
                          className="check text green"
                        />
                      </div>
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Bottom_Successfull_Modal
        show={showModal}
        onHide={handleCloseModal}
        text="Item Deleted from the cart successfully!"
      />
    </>
  );
};

export default CartPage;
